/* fonts */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local('Poppins'), url('../fonts/poppins/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: bold;
  font-weight: 700;
  src: local('Poppins'), url('../fonts/poppins/Poppins-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: black;
  font-weight: 900;
  src: local('Poppins'), url('../fonts/poppins/Poppins-Black.ttf') format('truetype');
}

/* fontawesome */
.fa-space-text {
  padding-right: 6px;
}
i {
  outline: none;
}