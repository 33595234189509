:root {
  font-size: 100%; /* define o tamanho padrão de todos os 
                     elementos e facilita a sua manipulação */ 
}

/* limpa css */
* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

/* define | a */
a {
  color: var(--color-base-app);
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
a:hover {
  color: var(--color-base-dark-app);
  text-decoration: underline;
  outline: none;
}
a:focus {
  color: var(--color-base-dark-app);
  text-decoration: underline;
  outline: none;
}

/* outros */
fieldset {
	border: 0;
}

/* define base css */
body {
  font-family: var(--font-primary1), var(--font-primary2), var(--font-primary3);
  color: var(--color-text);
  text-align: center;
  position: relative;
  height: 100%;
  background: -moz-linear-gradient(270deg, var(--color-base-dark-app) 0%, var(--color-base-light-app) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, var(--color-base-dark-app)), color-stop(100%, var(--color-base-light-app)));
  background: -webkit-linear-gradient(270deg, var(--color-base-dark-app) 0%, var(--color-base-light-app) 100%);
  background: linear-gradient(180deg, var(--color-base-dark-app) 0%, var(--color-base-light-app) 100%);
  overflow: hidden;
}

/* WRAP */
.wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* separa os elementos um para direito
                                     e o outro para esquerda */
  align-items: top;
  margin: auto;
  width: 100vw;
  height: 100vh;
  border-radius: 10px;
}

/* SMALL VIEWPORT */
@media only screen and (max-width: 40em) {
  .wrap {
    flex-direction: column;
    box-shadow: 0 2px 2px -1px;
    width: 100%;
    height: 100vh;
  }
}

/* MEDIUM VIEWPORT */
@media only screen and (min-width: 40em) {
  /* WRAP */
  .wrap {
    flex-direction: row;
    margin: auto;
    min-width: 600px;
    max-width: 900px;
    width: 95%;
    height: 95vh;
    border-radius: 10px;
  }
}

/* LARGE VIEWPORT */
@media only screen and (min-width: 60em) {
  /* WRAP */
  .wrap {
    flex-direction: row;
    margin: auto;
    min-width: 800px;
    max-width: 1100px;
    width: 95%;
    height: 95vh;
    border-radius: 10px;
  }
}