.header {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.header-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;

  width: 100%;
  height: 100%;
  margin: auto;
  padding: 20px;
  border-radius: 10px 10px 0 0;

  background: -moz-linear-gradient(90deg, var(--color-base-app) 0%, var(--color-base-app) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, var(--color-base-app)), color-stop(100%, var(--color-base-light-app)));
	background: -webkit-linear-gradient(90deg, var(--color-base-app) 0%, var(--color-base-app) 100%);
	background: linear-gradient(0deg, var(--color-base-app) 0%, var(--color-base-app) 100%);

  /* background: #fff; */
}

.header-page-logo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.header-page-logo img {
  height: 40px;
}

.header-page-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right;
  color: var(--color-white);
}
.header-page-content p {
  font-size: 0.8rem;
}
.header-page-content strong {
  display: block;
  font-size: 0.9rem;
}

/* SMALL VIEWPORT */
@media only screen and (max-width: 40em) {
	.header-page {
		border-radius: 0;
	}

  .header-page-content {
    justify-content: center;
  }
  .header-page-content p {
    font-size: 0.8rem;
  }
  .header-page-content strong {
    display: none;
  }
}


.header-page-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;
  margin: auto;
  padding: 20px;
  border-radius: 10px 10px 0 0;

  background: -moz-linear-gradient(90deg, var(--color-base-app) 0%, var(--color-base-app) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, var(--color-base-app)), color-stop(100%, var(--color-base-light-app)));
	background: -webkit-linear-gradient(90deg, var(--color-base-app) 0%, var(--color-base-app) 100%);
	background: linear-gradient(0deg, var(--color-base-app) 0%, var(--color-base-app) 100%);

  /* background: #fff; */
}

.header-page-navigation-home i {
  color: var(--color-white);
  font-size: 2rem;
  text-decoration: none;
  transition: 0.3s;
}
.header-page-navigation-home:hover i {
  color: var(--color-base-dark-app);
  text-decoration: none;
  transition: 0.3s;
}
.header-page-navigation-home:focus i {
  color: var(--color-white);
  transition: 0.3s;
}

.header-page-navigation-back i {
  color: var(--color-white);
  font-size: 1.5rem;
  text-decoration: none;
  transition: 0.3s;
}
.header-page-navigation-back:hover i {
  color: var(--color-base-dark-app);
  text-decoration: none;
  transition: 0.3s;
}
.header-page-navigation-back:focus i {
  color: var(--color-white);
  transition: 0.3s;
}

.header-page-navigation-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  color: var(--color-white);
}
.header-page-navigation-content p {
  font-size: 0.8rem;
}
.header-page-navigation-content strong {
  font-size: 1.2rem;
  font-weight: normal;
}

/* SMALL VIEWPORT */
@media only screen and (max-width: 40em) {
	.header-page-navigation {
		border-radius: 0;
	}

  .header-page-navigation-content {
    justify-content: center;
  }
  .header-page-navigation-content p {
    font-size: 0.7rem;
  }
}