#remuneracao-funcionarios-container {
	/* ocupar largura e altura total da pagina */
	width: 100vw;
	height: 100vh;

	/* centraliza verticalmente e horizontalmente */
	display: flex;
	justify-content: center;
	align-items: top;
}

.remuneracao-funcionarios-header {
	display: fixed;
	height: 80px;
	border-radius: 0;
	box-shadow: 0 4px 4px -2px;
	margin-bottom: 8px; 
}

.remuneracao-funcionarios-base {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	margin: 0 auto;
	width: 100%;
	height: 100%;
	border-radius: 10px;

  background-color: var(--color-base-dark-app);
}

.remuneracao-funcionarios-wrap {
	display: grid;
	grid-template-columns: 0 auto 0;
	grid-template-rows: auto 60px;

	justify-content: initial;

	margin: auto;
	padding: 0;

	width: 100%;
	height: 100%;

	overflow-y: auto;

	/* background: #444; */
}

.remuneracao-funcionarios-content {
	grid-column-start: 2;
  grid-column-end: 2;
	grid-row-start: 1;
	grid-row-end: 1;

	width: 100%;
	height: 100%;

  background-color: var(--color-background);

	/* border: 1px solid red; */
}


.remuneracao-funcionarios-content-link { 
	color: var(--color-black);
  text-decoration: none;
} 
.remuneracao-funcionarios-content-link:hover { 
	color: var(--color-black);
  text-decoration: none;
} 

.remuneracao-funcionarios-content table { 
	border-collapse: collapse; 
	width: 100%; 
} 
.remuneracao-funcionarios-content th, .remuneracao-funcionarios-content td { 
	text-align: left; 
	padding: 8px; 
} 
.remuneracao-funcionarios-content tr:nth-child(even) { 
	background-color: var(--color-row-line1);
} 
.remuneracao-funcionarios-content tr:nth-child(odd) {
	background-color: var(--color-row-line2);
}

.remuneracao-funcionarios-table-linha1 { 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%; 
  padding-left: 10px;
  padding-right: 10px;
}
.remuneracao-funcionarios-table-nome {
  position: relative !important;
}
.remuneracao-funcionarios-table-nome p { 
  text-align: left !important;
  font-size: .6rem !important; 
} 
.remuneracao-funcionarios-table-nome strong { 
  transition: .5s;
  width: 100% !important; 
  text-align: left !important;
  font-size: .9rem !important; 
  white-space: nowrap !important; 
  position: absolute !important; 
} 
.remuneracao-funcionarios-table-matricula {
  position: relative !important;
}
.remuneracao-funcionarios-table-matricula p { 
  text-align: right !important;
  font-size: .6rem !important; 
} 
.remuneracao-funcionarios-table-matricula strong { 
  transition: .5s;
  width: 100% !important; 
  text-align: right !important;
	font-size: .9rem !important; 
  white-space: nowrap !important; 
}

.remuneracao-funcionarios-table-linha2 { 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%; 
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 2px;
}
.remuneracao-funcionarios-table-empresa {
  position: relative !important;
}
.remuneracao-funcionarios-table-empresa p { 
  text-align: left !important;
  font-size: .6rem !important; 
} 
.remuneracao-funcionarios-table-empresa strong { 
  transition: .5s;
  width: 100% !important; 
  text-align: left !important;
  font-size: .9rem !important; 
  white-space: nowrap !important; 
  position: absolute !important;
} 
.remuneracao-funcionarios-table-funcao {
  position: relative !important;
}
.remuneracao-funcionarios-table-funcao p { 
  text-align: right !important;
  font-size: .6rem !important; 
} 
.remuneracao-funcionarios-table-funcao strong { 
  transition: .5s;
  width: 100% !important; 
  text-align: right !important;
	font-size: .9rem !important; 
  white-space: nowrap !important; 
}



.remuneracao-funcionarios-table-td-laterais { 
	/* width: 10px !important;  */
  padding: 0 !important; margin: 0 !important; 
}


.remuneracao-funcionarios-search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
	height: 40px;
	border-radius: 0;
	padding-left: 10px;
	padding-right: 10px;
  padding-bottom: 8px;

  background-color: var(--color-base-dark-app);

  color: var(--color-white);
}

.remuneracao-funcionarios-footer {
	grid-column-start: 1;
  grid-column-end: 4;
	grid-row-start: 2;
	grid-row-end: 2;

	height: 60px;
	border-radius: 0;
	box-shadow: 0 0 4px 2px;
	/* margin-top: 8px; */
}


/* SMALL VIEWPORT */
@media only screen and (max-width: 40em) {
}

/* MEDIUM VIEWPORT */
@media only screen and (min-width: 40em) {
  .remuneracao-funcionarios-table-nome p { 
    font-size: .8rem !important; 
  } 
  .remuneracao-funcionarios-table-nome strong { 
    font-size: 1.1rem !important; 
  } 
  .remuneracao-funcionarios-table-matricula p { 
    font-size: .8rem !important; 
  } 
  .remuneracao-funcionarios-table-matricula strong { 
    font-size: 1.1rem !important; 
  }

  .remuneracao-funcionarios-table-empresa p { 
    font-size: .8rem !important; 
  } 
  .remuneracao-funcionarios-table-empresa strong { 
    font-size: 1.1rem !important; 
  } 
  .remuneracao-funcionarios-table-funcao p { 
    font-size: .8rem !important; 
  } 
  .remuneracao-funcionarios-table-funcao strong { 
    font-size: 1.1rem !important; 
  }

	.remuneracao-funcionarios-table-td-laterais { 
		/* width: 10px !important;  */
	}
}

/* LARGE VIEWPORT */
@media only screen and (min-width: 60em) {
  .remuneracao-funcionarios-table-linha1 { 
    padding-left: 15px;
    padding-right: 15px;
  }
  .remuneracao-funcionarios-table-nome p { 
    font-size: 1rem !important; 
  } 
  .remuneracao-funcionarios-table-nome strong { 
    font-size: 1.3rem !important; 
  } 
  .remuneracao-funcionarios-table-matricula p { 
    font-size: 1rem !important; 
  } 
  .remuneracao-funcionarios-table-matricula strong { 
    font-size: 1.3rem !important; 
  }

  .remuneracao-funcionarios-table-linha2 { 
    padding-left: 15px;
    padding-right: 15px;
  }
  .remuneracao-funcionarios-table-empresa p { 
    font-size: 1rem !important; 
  } 
  .remuneracao-funcionarios-table-empresa strong { 
    font-size: 1.3rem !important; 
  } 
  .remuneracao-funcionarios-table-funcao p { 
    font-size: 1rem !important; 
  } 
  .remuneracao-funcionarios-table-funcao strong { 
    font-size: 1.3rem !important; 
  }

	.remuneracao-funcionarios-table-td-laterais { 
		/* width: 15px !important;  */
	}
}