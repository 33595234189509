#main-container {
	/* ocupar largura e altura total da pagina */
	width: 100vw;
	height: 100vh;

	/* centraliza verticalmente e horizontalmente */
	display: flex;
	justify-content: center;
	align-items: top;
}

.main-header {
	display: fixed;
	height: 80px;
	border-radius: 0;
	box-shadow: 0 4px 4px -2px;
	margin-bottom: 8px; 
}

.main-base {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	margin: 0 auto;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	
	background: url('../../assets/images/slides/slide00_text_1800x650.png')
							no-repeat center top fixed;
	background-size: 1100px 250px;
	background-position-y: 80px;
	background-attachment: fixed;

	background-color: var(--color-background);
}

.main-wrap {
	display: grid;
	grid-template-columns: 20px auto 20px;
	grid-template-rows: auto 60px;

	justify-content: initial;

	margin: auto;
	padding: 0;
	padding-top: 180px;
	transition: 0.5s;

	width: 100%;
	height: 100%;

	overflow-y: auto;

	/* background: #444; */
}

.main-content {
	grid-column-start: 2;
  grid-column-end: 2;
	grid-row-start: 1;
	grid-row-end: 1;

	width: 100%;
	height: 100%;

	/* border: 1px solid red; */
}

.main-footer {
	grid-column-start: 1;
  grid-column-end: 4;
	grid-row-start: 2;
	grid-row-end: 2;

	height: 60px;
	border-radius: 0;
	box-shadow: 0 0 4px 2px;
	/* margin-top: 8px; */
}

.main-menu {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-self: center;
	width: 100%;
	/* border: 1px solid red; */
}
.main-menu-link {
	text-decoration: none !important;
}
#main-menu-card {
	transition: 1s;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-self: center;
	width: 100%;
	height: 100%;
	min-height: 150px;
	background-color: var(--color-text-gray);
	border-radius: 10px;
	/* border: 1px solid green; */
	box-shadow: 4px 4px 4px -2px var(--color-black);
}
.main-menu-card-id {
	text-decoration: none;
	color: var(--color-white);
	background-color: var(--color-base-app);
	border-radius: 10px 0 0 10px;
	padding: 20px;
	height: 100%;
  justify-content: center;
	align-items: center;
	margin: auto;
}
.main-menu-card-id i {
	font-size: 4rem;
}
.main-menu-card-content {
	width: 100%;
	padding: 20px;
	text-align: left;
}
.main-menu-card-content strong {
	font-size: 1.4rem;
}
.main-menu-card-content p {
	padding-top: 10px;
	font-size: 1rem;
}

/* SMALL VIEWPORT */
@media only screen and (max-width: 40em) {
	.wrap {
		background-color: var(--color-background)
	}
	.main-base {
		border-radius: 0;
		background-size: contain;
	}
	.main-wrap {
		padding-top: 140px;
	}
	.main-content {
		/* height: 80px;
		border-radius: 0;
		box-shadow: 0 4px 4px -2px;
		margin-bottom: 8px; */
	}

	.main-menu-card-content strong {
		font-size: 1rem;
	}
	.main-menu-card-content p {
		padding-top: 10px;
		font-size: .8rem;
	}
}

/* SMALL VIEWPORT */
@media only screen and (max-width: 30em) {
	.main-wrap {
		padding-top: 100px;
	}
}

/* MEDIUM VIEWPORT */
@media only screen and (min-width: 40em) {
	.main-menu-card-content strong {
		font-size: 1.2rem;
	}
	.main-menu-card-content p {
		padding-top: 10px;
		font-size: .9rem;
	}
}

/* LARGE VIEWPORT */
@media only screen and (min-width: 60em) {
	.main-menu-card-content strong {
		font-size: 1.4rem;
	}
	.main-menu-card-content p {
		padding-top: 10px;
		font-size: 1rem;
	}
}