/* LOGO */
.header-login-logo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 40px;
	margin: auto;
  width: 100%;
  height: 100%;

  background-image: url("../../assets/images/img_fundo_dots.png");
	background-repeat: no-repeat;
	background-position: 50% 120%;
}
.header-login-logo img {
	width: 220px;
  transition: 0.3s;
}
.header-login-logo a {
}
.header-login-id-logo {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.header-login-id-logo img {
	width: 80px;
  transition: 0.3s;
}
#header-login-logo-small {
  display: none;
}
#header-login-id-logo {
  display: block;
}
.header-login-logo-fundo {
  position: absolute;
  border: 1px solid var(--color-text-gray);
  width: 100%;
	height: 100%;
}

/* MEDIUM VIEWPORT */
@media only screen and (min-width: 60em) {
  .header-login-logo img {
    width: 280px;
    transition: 0.3s;
  }
  .header-login-id-logo img {
    width: 80px;
    transition: 0.3s;
  }
}

/* SMALL VIEWPORT */
@media only screen and (max-width: 40em) {
  .header-login-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-image: none;
  }
	.header-login-logo img {
    width: auto;
    height: 41px;
    display: none;
    transition: 0.3s;
  }
  .header-login-id-logo img {
    width: auto;
    height: 40px;
    transition: 0.3s;
  }
  #header-login-logo-small {
    display: block;
  }
  #header-login-id-logo {
    display: block;
  }
}