#splash-container {
	/* fundo movel */
	background: linear-gradient(-45deg,	
	var(--color-base-app), var(--color-base-dark-app), 
	var(--color-base-app), var(--color-base-light-app)
	);
	background-size: 1200% 1200%;
	animation: gradient 3s ease infinite;

	/* ocupar largura e altura total da pagina */
  width: 100vw;
  height: 100vh;

	/* centraliza verticalmente e horizontalmente */
	display: flex;
	justify-content: center;
	align-items: center;
}

/* define gradient para fundo movel */
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

#splash-display {
	/* ocupar largura e altura total da pagina */
  width: 100vw;
  height: 100vh;

	/* centraliza verticalmente e horizontalmente */
	display: flex;
	justify-content: center;
	align-items: center;
}

.splash-static {
	/* manter fixa posicao */
}

.splash-move {
	/* realizar movimento da pagina */
	transform: translateX(-100%);
  transition: transform ease-out 1s;
}