/* define | button */
button {
	font-family: var(--font-primary1), var(--font-primary2), var(--font-primary3);
	margin: 1em 0;
	padding: .2em 3em;
	width: auto;
	display: block;
	background-color: var(--color-base-app);
	border: none;
	color: var(--color-white);
	cursor: pointer;
  outline: none;
	width: 100%;
	height: 36px;
	border-radius: 4px;
	background: -moz-linear-gradient(270deg, var(--color-base-light-app) 0%, var(--color-base-app) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, var(--color-base-light-app)), color-stop(100%, var(--color-base-light-app)));
	background: -webkit-linear-gradient(270deg, var(--color-base-light-app) 0%, var(--color-base-app) 100%);
	background: linear-gradient(180deg, var(--color-base-light-app) 0%, var(--color-base-app) 100%);
	-webkit-box-shadow: 0px 0px 17px -9px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 17px -9px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 17px -9px rgba(0,0,0,0.75);
}
button:hover {
  color: var(--color-text-gray);
  opacity: 0.8;
  background-color: var(--color-base-dark-app);
	background: -moz-linear-gradient(270deg, var(--color-base-dark-app) 0%, var(--color-base-dark-app) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, var(--color-base-dark-app)), color-stop(100%, var(--color-base-dark-app)));
	background: -webkit-linear-gradient(270deg, var(--color-base-dark-app) 0%, var(--color-base-dark-app) 100%);
	background: linear-gradient(180deg, var(--color-base-dark-app) 0%, var(--color-base-dark-app) 100%);
}
button:focus {
  color: var(--color-text-gray);
  opacity: 0.8;
  background-color: var(--color-base-dark-app);
	background: -moz-linear-gradient(270deg, var(--color-base-dark-app) 0%, var(--color-base-dark-app) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, var(--color-base-dark-app)), color-stop(100%, var(--color-base-dark-app)));
	background: -webkit-linear-gradient(270deg, var(--color-base-dark-app) 0%, var(--color-base-dark-app) 100%);
	background: linear-gradient(180deg, var(--color-base-dark-app) 0%, var(--color-base-dark-app) 100%);
}