.splash-animation
h1 span {
  position: relative;
  top: -180px;
  display: none;
  animation: animation-bounce1 1.8s ease alternate;
  transition: 1s;
}

.splash-animation
h2 span {
  position: relative;
  top: -200px;
  display: none;
  animation: animation-bounce2-large 1.8s ease alternate;
  transition: 1s;
}

.splash-animation
h1 span:nth-child(1) { animation-delay: -.1s; z-index: 1; display: inline-block; }
h1 span:nth-child(2) { animation-delay: -.2s; z-index: 1; display: inline-block; }
h1 span:nth-child(3) { animation-delay: -.3s; z-index: 1; display: inline-block; }
h1 span:nth-child(4) { animation-delay: -.4s; z-index: 1; display: inline-block; }
h1 span:nth-child(5) { animation-delay: -.5s; z-index: 1; display: inline-block; }
h1 span:nth-child(6) { animation-delay: -.6s; z-index: 1; display: inline-block; }
h1 span:nth-child(7) { animation-delay: -.7s; z-index: 1; display: inline-block; }

.splash-animation
h2 span:nth-child(1) { animation-delay: 0; z-index: 0; display: inline-block; }

.splash-d1 { position: absolute; left: -265px; }
.splash-a1 { position: absolute; left: -145px; }
.splash-t1 { position: absolute; left: -75px; }
.splash-a2 { position: absolute; left: 0; }
.splash-z1 { position: absolute; left: 115px; }
.splash-consultoria1 { position: absolute; left: -265px; top: 180px; width: 530px; }

@keyframes animation-bounce1 {
  0% { top: -5000px; }
  50% { top: -100px; }
  75% { top: -150px; }
  100% { top: -200px; }
}

@keyframes animation-bounce2-large {
  0% { top: -5000px; }
  50% { top: -120px; }
  75% { top: -170px; }
  100% { top: -220px; }
}

@keyframes animation-bounce2-medium {
  0% { top: -5000px; }
  50% { top: -120px; }
  75% { top: -170px; }
  100% { top: -150px; }
}

@keyframes animation-bounce2-small {
  0% { top: -5000px; }
  50% { top: -90px; }
  75% { top: -130px; }
  100% { top: -110px; }
}

/* SMALL VIEWPORT */
@media only screen and (max-width: 40em) {
  .splash-animation
  h1 span {
    top: -80px;
    -ms-transform: scale(.5); /* IE 9 */
    transform: scale(.5);
  }
  .splash-animation
  h2 span {
    top: -110px;
    animation: animation-bounce2-small 1.8s ease alternate;
    -ms-transform: scale(.5); /* IE 9 */
    transform: scale(.5);
  }
}

/* MEDIUM VIEWPORT */
@media only screen and (min-width: 40em) {
  .splash-animation
  h1 span {
    top: -120px;
    -ms-transform: scale(.8); /* IE 9 */
    transform: scale(.8);
  }
  .splash-animation
  h2 span {
    top: -140px;
    animation: animation-bounce2-medium 1.8s ease alternate;
    -ms-transform: scale(.8); /* IE 9 */
    transform: scale(.8);
  }
}

/* LARGE VIEWPORT */
@media only screen and (min-width: 60em) {
  .splash-animation
  h1 span {
    top: -180px;
    -ms-transform: scale(1); /* IE 9 */
    transform: scale(1);
  }
  .splash-animation
  h2 span {
    top: -200px;
    animation: animation-bounce2-large 1.8s ease alternate;
    -ms-transform: scale(1); /* IE 9 */
    transform: scale(1);
  }
}