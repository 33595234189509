.politicaprivacidade-login {
	position: relative;
	width: 100%;
	height: 100%;
	
	overflow-y: auto;
}

.politicaprivacidade-login h4 {
	font-size: 1rem;
	text-align: center; 
	padding-bottom: 5px;
}
.politicaprivacidade-login h5 {
	font-size: 0.8rem;
	text-align: left; 
	padding-top: 5px;
	padding-bottom: 5px;
}
.politicaprivacidade-login p {
	font-size: 0.8rem;
	text-align: left; 
	padding-bottom: 5px;
}