.footer-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;

  width: 100%;
  height: 100%;
  margin: auto;
  padding: 20px;
  border-radius: 0 0 10px 10px;

  background: -moz-linear-gradient(90deg, var(--color-base-app) 0%, var(--color-base-app) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, var(--color-base-app)), color-stop(100%, var(--color-base-light-app)));
	background: -webkit-linear-gradient(90deg, var(--color-base-app) 0%, var(--color-base-app) 100%);
	background: linear-gradient(0deg, var(--color-base-app) 0%, var(--color-base-app) 100%);

  /* background: #fff; */
}

.footer-page-logo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.footer-page-logo img {
  height: 35px;
}

.footer-page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
}
.footer-page-content p {
  font-size: 0.7rem;
}
.footer-page-content strong {
  font-size: 0.8rem;
}

.footer-page-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  color: var(--color-white);
}


/* SMALL VIEWPORT */
@media only screen and (max-width: 40em) {
	.footer-page {
		border-radius: 0;
	}
  .footer-page-content p {
    font-size: 0.5rem;
  }
  .footer-page-content strong {
    font-size: 0.6rem;
  }
}


html {
  scroll-behavior: smooth;
}

.scrollToTop {
  color: var(--color-white);
  font-size: 2rem;
  text-decoration: none;
  transition: 0.3s;
}
.scrollToTop:hover {
  color: var(--color-base-dark-app);
  text-decoration: none;
  transition: 0.3s;
}
.scrollToTop:focus {
  color: var(--color-white);
  transition: 0.3s;
}

.scrollToTop-Show {
  display: block;
}
.scrollToTop-Hide {
  display: none;
}
