#remuneracao-container {
	/* ocupar largura e altura total da pagina */
	width: 100vw;
	height: 100vh;

	/* centraliza verticalmente e horizontalmente */
	display: flex;
	justify-content: center;
	align-items: top;
}

.remuneracao-header {
	display: fixed;
	height: 80px;
	border-radius: 0;
	box-shadow: 0 4px 4px -2px;
	margin-bottom: 8px; 
}

.remuneracao-base {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	margin: 0 auto;
	width: 100%;
	height: 100%;
	border-radius: 10px;

  background-color: var(--color-base-dark-app);
}

.remuneracao-wrap {
	display: grid;
	grid-template-columns: 0 auto 0;
	grid-template-rows: auto 60px;

	justify-content: initial;

	margin: auto;
	padding: 0;

	width: 100%;
	height: 100%;

	overflow-y: auto;

	/* background: #444; */
}

.remuneracao-content {
	grid-column-start: 2;
  grid-column-end: 2;
	grid-row-start: 1;
	grid-row-end: 1;

	width: 100%;
	height: 100%;

  background-color: var(--color-background);

	/* border: 1px solid red; */
}


.remuneracao-content table { 
	border-collapse: collapse; 
	width: 100%; 
} 
.remuneracao-content th, .remuneracao-content td { 
	text-align: left; 
	padding: 8px; 
} 
.remuneracao-content tr:nth-child(even) { 
	background-color: var(--color-row-line1);
} 
.remuneracao-content tr:nth-child(odd) {
	background-color: var(--color-row-line2);
}
.remuneracao-table-td-mes { 
	transition: .5s;
	font-size: .8rem !important; 
	padding-right: 5px !important; 
} 
.remuneracao-table-td-ano { 
	transition: .5s;
	text-align: right !important; 
	font-size: .8rem !important; 
	padding-left: 5px !important; 
} 
.remuneracao-table-td-valor { 
	transition: .5s;
	width: 100vw !important; 
	text-align: right !important; 
	font-size: .8rem !important; 
	padding-left: 20px !important; 
} 
.remuneracao-table-td-laterais { 
	width: 10px !important; 
}


.remuneracao-search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
	height: 40px;
	border-radius: 0;
	padding-left: 10px;
	padding-right: 10px;
  padding-bottom: 8px;

  background-color: var(--color-base-dark-app);

  color: var(--color-white);
}

.remuneracao-footer {
	grid-column-start: 1;
  grid-column-end: 4;
	grid-row-start: 2;
	grid-row-end: 2;

	height: 60px;
	border-radius: 0;
	box-shadow: 0 0 4px 2px;
	/* margin-top: 8px; */
}


/* SMALL VIEWPORT */
@media only screen and (max-width: 40em) {
}

/* MEDIUM VIEWPORT */
@media only screen and (min-width: 40em) {
	.remuneracao-table-td-mes { 
		font-size: .9rem !important; 
	} 
	.remuneracao-table-td-ano { 
		font-size: .9rem !important; 
	} 
	.remuneracao-table-td-valor { 
		font-size: .9rem !important; 
	} 
	.remuneracao-table-td-laterais { 
		width: 10% !important; 
	}
}

/* LARGE VIEWPORT */
@media only screen and (min-width: 60em) {
	.remuneracao-table-td-mes { 
		font-size: 1rem !important; 
	} 
	.remuneracao-table-td-ano { 
		font-size: 1rem !important; 
	} 
	.remuneracao-table-td-valor { 
		font-size: 1rem !important; 
	} 
	.remuneracao-table-td-laterais { 
		width: 15% !important; 
	}
}