:root {
  /* variaveis do app */
  /* https://www.w3schools.com/colors/colors_picker.asp */

  --color-base-app: #337ab7; /* cor base */
  --color-base-light-app: #609dd2; /* três pontos para cima - mais claro */
  --color-base-dark-app: #275d8b; /* três pontos para baixo - mais escuro */
  --color-base-focus-app: #1e90ff; /* cor base focus */

  --color-base-light-background: #ffffff; /* três pontos para cima - mais claro */
  --color-base-dark-background: #dddddd; /* três pontos para baixo - mais escuro */

  --color-background: #eeeeee;
  --color-text: #262626;
  --color-text-gray: #dddddd;

  --color-row-line1: #efefef;
  --color-row-line2: #f9f9f9;
  --color-row-checked: #ffffc0;

  --color-white: #fff;
  --color-black: #000;
  --color-gray: #999;
  --color-light-gray: #bbb;

  --font-primary1: 'Poppins';
  --font-primary2: 'Arial';
  --font-primary3: 'serif';
  --font-fontawesome: 'Font Awesome 5 Pro';

  /* OUTROS */
  --color-background: #F0F0F7;
  --color-primary-lighter: #9871F5;
  --color-primary-light: #916BEA;
  --color-primary: #8257E5;
  --color-primary-dark: #774DD6;
  --color-primary-darker: #6842C2;
  --color-secundary: #04D361;
  --color-secundary-dark: #04BF58;
  --color-title-in-primary: #FFFFFF;
  --color-text-in-primary: #D4C2FF;
  --color-text-title: #32264D;
  --color-text-complement: #9C98A6;
  --color-text-base: #6A6180;
  --color-line-in-white: #E6E6F0;
  --color-input-background: #F8F8FC;
  --color-button-text: #FFFFFF;
  --color-box-base: #FFFFFF;
  --color-box-footer: #FAFAFC;
}