#login-container {
	/* ocupar largura e altura total da pagina */
  width: 100vw;
  height: 100vh;

	/* centraliza verticalmente e horizontalmente */
	display: flex;
	justify-content: center;
	align-items: top;
}

#login-display {
	/* ocupar largura e altura total da pagina */
  width: 100vw;
  height: 100vh;

	/* centraliza verticalmente e horizontalmente */
	display: flex;
	justify-content: center;
	align-items: top;
}

.login-static {
	/* posicionar a pagina */
	transform: translateX(-100%);
  transition: transform ease-out 0.5s;
}

.login-move {
	/* realizar movimento da pagina */
	transform: translateX(0%);
  transition: transform ease-out 1s;
}

.login-content {
	margin: 0 auto;
	width: 100%;
	height: 100%;
	border-radius: 10px 0 0 10px;
	
	background: -moz-linear-gradient(90deg, var(--color-base-app) 0%, var(--color-base-app) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, var(--color-base-app)), color-stop(100%, var(--color-base-light-app)));
	background: -webkit-linear-gradient(90deg, var(--color-base-app) 0%, var(--color-base-app) 100%);
	background: linear-gradient(0deg, var(--color-base-app) 0%, var(--color-base-app) 100%);
	overflow: hidden;
}

.login-user {
  /* fundo movel */
	background: linear-gradient(-45deg,	
		var(--color-background), var(--color-base-dark-background), 
		var(--color-background), var(--color-base-light-background)
    );
	background-size: 1200% 1200%;
	animation: gradient 3s ease infinite;

	margin: 0 auto;
	width: 100%;
	height: 100%;
	border-radius: 0 10px 10px 0;

	display: flex;
	flex-direction: row;
	align-items: center;
}

/* define gradient para fundo movel */
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.login-form-wrap {
	/* border:1px solid #000; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin: 1em auto 0;
	width: 100%;
	height: 90%;
	margin-top: 0;

	overflow-y: auto;
}

.login-tabs {
}
.login-tab {
	color: var(--color-black);
}
.login-title {
	color: var(--color-black);
	padding-bottom: 2.5rem;
}

.login-tabs-content {
	display: flex; 
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
}
#login-tab-content {
	display: flex; 
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 40px;
	padding-top: 20px;
	padding-bottom: 20px;
}
.login-form {
}

.login-links {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 4rem;
}
.login-links a {
  font-size: 0.8rem;
}

.login-footer {
	margin: 15px;
}
.login-footer a {
	color: var(--color-black);
	font-size: 0.8rem;
}

/* SMALL VIEWPORT */
@media only screen and (max-width: 40em) {
	.wrap {
		background-color: var(--color-background)
	}
	.login-content {
		height: 80px;
		border-radius: 0;
		box-shadow: 0 4px 4px -2px;
		margin-bottom: 8px;
	}
	.login-user {
		border-radius: 0;
	}
}

/* LOGIN */
.login-user-hide {
	display: none;
}
.login-user-show {
	display: flex;
}

/* RECUPERAR SENHA */
.login-recuperarsenha-hide {
	display: none; 
}
.login-recuperarsenha-show {
	display: flex; 

	/* border:1px solid #000; */
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin: 1em auto 0;
	width: 100%;
	height: 100%;
	margin-top: 0;
	padding: 0;

	overflow-y: auto;
}
.login-tab-recuperarsenha {
	display: flex; 
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 40px;
}
.login-tab-recuperarsenha button {
	margin-top: 35px;
}

/* ALTERAR SENHA */
.login-alterarsenha-hide {
	display: none;
}
.login-alterarsenha-show {
	display: flex;

	/* border:1px solid #000; */
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin: 1em auto 0;
	width: 100%;
	height: 100%;
	margin-top: 0;
	padding: 0;

	overflow-y: auto;
}
.login-tab-alterarsenha {
	display: flex; 
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 40px;
}
.login-tab-alterarsenha button {
	margin-top: 35px;
}

/* POLITICA PRIVACIDADE */
.login-politicaprivacidade-hide {
	display: none;
}
.login-politicaprivacidade-show {
	display: flex;

	/* border:1px solid #000; */
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin: 1em auto 0;
	width: 90%;
	height: 90%;
	margin-top: 0;
}

.login-button-close {
	position: relative;
	width: 100%;
}
.login-button-close a {
	padding-top: 20px;
	padding-right: 25px;
}

.login-button-close-politicaprivacidade {
	position: relative;
	width: 100%;
}
.login-button-close-politicaprivacidade a {
	top: -25px;
	right: 0;
}