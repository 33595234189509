.search-base {
	position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  width: 100%;
}
.search-base label {
	font-size: 0.8rem;

	order: 1;
}
.search-base input {
	font-family: var(--font-fontawesome), var(--font-primary1), var(--font-primary2), var(--font-primary3);
	font-size: 1.2rem;
	margin: 0 0 .7em 0;
	padding: .6em .5em 5px 0;
	width: 100%;
	display: inline-block;
	background: transparent;
	border: 0;
	border-bottom: 1px solid transparent;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	border-radius: 0;
	outline: 0;
	color: inherit;
	color: var(--color-white);
	padding-left: 5px;
	transition: 0.3s;
}
.search-base input:focus {
	border-bottom: 1px solid var(--color-white);
	box-shadow: 0 4px 4px -4px var(--color-white);
}
.search-base i {
  position: absolute;
  left: 0;
  top: 4px;
  padding: 9px 8px;
  color: var(--color-white);
  transition: 0.3s;
}
.search-base input:focus + label, .search-base input:focus + i {
	font-weight: bold;
  color: var(--color-white);
}
.search-base input:-webkit-autofill,
.search-base input:-webkit-autofill:hover,
.search-base input:-webkit-autofill:focus,
.search-base input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: var(--color-white);
}

::placeholder {
  color: var(--color-light-gray);
	font-size: 0.9rem;
}

.search-input {
  width: 100%;
}
.search-icon-search i {
  position: relative;
  align-items: left;
  font-size: 1.4rem;
  color: var(--color-white);
  padding-right: 20px;
  padding-top: -1px;
}
.search-icon-clean i {
  position: relative;
  align-items: right;
  font-size: 1.4rem;
  color: var(--color-white);
  padding-left: 20px;
  padding-top: -1px;
}
.search-icon-clean {
  display: block;
}
.search-icon-clean-hide {
  display: none;
}