.input-base {
  position: relative;
  text-align: left;
  }
.input-base label {
  font-size: 0.8rem;
}
.input-base input {
	font-family: var(--font-fontawesome), var(--font-primary1), var(--font-primary2), var(--font-primary3);
	font-size: 1rem;
	margin: 0 0 .5em 0;
	/* padding: .7em .8em 8px 0; */
	padding: .6em .8em 8px 0;
	width: 100%;
	display: inline-block;
	background: var(--color-white);
	border: 1px solid var(--color-black);
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	border-radius: 4px;
	outline: 0;
	color: inherit;
	color: var(--color-black);
	padding-left: 40px;
	transition: 0.3s;
}
.input-base input:focus {
	border: 1px solid var(--color-base-focus-app);
	/* box-shadow: 0 4px 4px -4px var(--color-base-focus-app); */
	box-shadow: 0 0 6px 0 var(--color-base-focus-app);
}
.input-base i {
  position: absolute;
  left: 0;
  top: 26px;
	height: 34px;
	padding: 8px 11px;
	border-radius: 4px 0 0 4px;
	color: var(--color-light-gray);
  transition: 0.3s;
}
.input-base input:focus + i {
  color: var(--color-white);
  background-color: var(--color-base-focus-app);
}
.input-base input:-webkit-autofill,
.input-base input:-webkit-autofill:hover,
.input-base input:-webkit-autofill:focus,
.input-base input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
		-webkit-text-fill-color: var(--color-black);
}

.input-login {
	position: relative;
  text-align: left;
}
.input-login label {
	font-size: 0.8rem;

	order: 1;
}
.input-login input {
	font-family: var(--font-fontawesome), var(--font-primary1), var(--font-primary2), var(--font-primary3);
	font-size: 1rem;
	margin: 0 0 .8em 0;
	padding: .8em .5em 10px 0;
	width: 100%;
	display: inline-block;
	background: transparent;
	border: 0;
	border-bottom: 1px solid var(--color-black);
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	border-radius: 0;
	outline: 0;
	color: inherit;
	color: var(--color-black);
	padding-left: 35px;
	transition: 0.3s;
}
.input-login input:focus {
	border-bottom: 1px solid var(--color-base-focus-app);
	box-shadow: 0 4px 4px -4px var(--color-base-focus-app);
}
.input-login i {
  position: absolute;
  left: 0;
  top: 28px;
  padding: 9px 8px;
  color: var(--color-light-gray);
  transition: 0.3s;
}
.input-login input:focus + label, .input-login input:focus + i {
	font-weight: bold;
  color: var(--color-base-focus-app);
}
.input-login input:-webkit-autofill,
.input-login input:-webkit-autofill:hover,
.input-login input:-webkit-autofill:focus,
.input-login input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
		-webkit-text-fill-color: var(--color-black);
}

::placeholder {
  color: var(--color-light-gray);
	font-size: 0.9rem;
}