.button-close {
	position: relative;
	width: 100%;
}
.button-close a {
	z-index: 1;
	position: absolute;
	margin: 0;
	padding: 0;
	top: 0;
  right: 0;
  color: var(--color-gray);
	font-size: 2rem;
	transition: 0.3s;
}
